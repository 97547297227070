// imagePaths.js
const imagePaths = {
	imgfolder: process.env.PUBLIC_URL + "/img/",
	addtocartimage: process.env.PUBLIC_URL + "/img/results/addtocart.png",
	addtocartimageask: process.env.PUBLIC_URL + "/img/results/addtocartask1.png",
	addtocartimagenostock:
		process.env.PUBLIC_URL + "/img/results/addtocartnostock.png",
	benfikoEmptyImage: process.env.PUBLIC_URL + "/img/logo/benfikoLogoBlack.png",
	autostarEmptyImage: process.env.PUBLIC_URL + "/img/logo/autostarLogoBlack.png",
	logoImage: process.env.PUBLIC_URL + "/img/logo.png",
	logoImagenav: process.env.PUBLIC_URL + "/img/logosmall.png",
	backtotopimage: process.env.PUBLIC_URL + "/img/backtotop/backtotopimage.png",
	//-----------------car logos ---------------------//
	audiimage: process.env.PUBLIC_URL + "/img/carmodels/AB_AUDI.png",
	vwimage: process.env.PUBLIC_URL + "/img/carmodels/AB_VW.png",
	mercedesimage: process.env.PUBLIC_URL + "/img/carmodels/AC_MERCEDES.png",
	seatimage: process.env.PUBLIC_URL + "/img/carmodels/AE_SEAT.png",
	skodaimage: process.env.PUBLIC_URL + "/img/carmodels/AG_SKODA.png",
	bmwimage: process.env.PUBLIC_URL + "/img/carmodels/AJ_BMW.png",
	alfaromeoimage: process.env.PUBLIC_URL + "/img/carmodels/ALFAROMEO.png",
	fiatimage: process.env.PUBLIC_URL + "/img/carmodels/FIAT.png",
	chevrolet: process.env.PUBLIC_URL + "/img/carmodels/Chevrolet.png",
	ford: process.env.PUBLIC_URL + "/img/carmodels/Ford.png",
	renault: process.env.PUBLIC_URL + "/img/carmodels/renault.png",
	opel: process.env.PUBLIC_URL + "/img/carmodels/OPEL.png",
	peugeot: process.env.PUBLIC_URL + "/img/carmodels/peugeot.png",
	citroen: process.env.PUBLIC_URL + "/img/carmodels/citroen.png",
	dacia: process.env.PUBLIC_URL + "/img/carmodels/dacia.png",
	mitsubishi:process.env.PUBLIC_URL+"/img/carmodels/Mitsubishi.png",
	landrover:process.env.PUBLIC_URL+"/img/carmodels/LANDROVER.png",
	suzuki:process.env.PUBLIC_URL+"/img/carmodels/suzuki.png",
	mazda:process.env.PUBLIC_URL+"/img/carmodels/mazda.png",
	toyota:process.env.PUBLIC_URL+"/img/carmodels/toyota.png",
	honda:process.env.PUBLIC_URL+"/img/carmodels/honda.png",
	nissan:process.env.PUBLIC_URL+"/img/carmodels/nissan.png",
	
//logos
	benfikoLogo: process.env.PUBLIC_URL + "/img/logo/benfikoLogo.png",
	benfikoLogoBlack: process.env.PUBLIC_URL + "/img/logo/benfikoLogoBlack.png",
	autostarLogo: process.env.PUBLIC_URL + "/img/logo/autostarLogo.png",
	autostarLogoBlack: process.env.PUBLIC_URL + "/img/logo/autostarLogoBlack.png",
	// brands images benfiko
	abe: process.env.PUBLIC_URL + "/img/brandparts/ABE.png",
	airtex: process.env.PUBLIC_URL + "/img/brandparts/AIRTEX.png",
	aspart: process.env.PUBLIC_URL + "/img/brandparts/ASPART.png",
	ate: process.env.PUBLIC_URL + "/img/brandparts/ATE.png",
	automega: process.env.PUBLIC_URL + "/img/brandparts/AUTOMEGA.png",
	ayd: process.env.PUBLIC_URL + "/img/brandparts/AYD.png",
	//per vajrat benfiko
	castrol:process.env.PUBLIC_URL+'/img/brandparts/CASTROL.png',
	mobil:process.env.PUBLIC_URL+'/img/brandparts/MOBIL.png',
	aral:process.env.PUBLIC_URL+'/img/brandparts/ARAL.png',
	shell:process.env.PUBLIC_URL+'/img/brandparts/SHELL.png',
	mercedes:process.env.PUBLIC_URL+'/img/brandparts/MERCEDES.png',
	//per vajrat autostar
	gmoil:process.env.PUBLIC_URL+'/img/brandparts/GM.png',
	total:process.env.PUBLIC_URL+'/img/brandparts/TOTAL.png',
	motorcraft:process.env.PUBLIC_URL+'/img/brandparts/MOTORCRAFT.png',
	rhcleaner:process.env.PUBLIC_URL+'/img/brandparts/ROADHOUSE.png',
	febilubricant:process.env.PUBLIC_URL+'/img/brandparts/FEBI BILSTEIN.png',
	kraftvolllubricants:process.env.PUBLIC_URL+'/img/brandparts/KRAFTVOLL LUBRICANTS.png',
	ravenol:process.env.PUBLIC_URL+'/img/brandparts/RAVENOL.png',
	braxislubricants:process.env.PUBLIC_URL+'/img/brandparts/BRAXIS LUBRICANTS.png',
	// add to cart images
	addtocart: process.env.PUBLIC_URL + "./img/results/addtocart.png",
	addtocartask: process.env.PUBLIC_URL + "./img/results/addtocart.png",
	addtocartnostock: process.env.PUBLIC_URL + "./img/results/addtocart.png",
	greencart:process.env.PUBLIC_URL+"./img/results/green_cart.png",
	yellowcart:process.env.PUBLIC_URL+"./img/results/yellow_cart.png",
	redcart:process.env.PUBLIC_URL+"./img/results/red_cart.png",
	morethan10:process.env.PUBLIC_URL+"./img/results/more_than_10.png",
	lessthan10:process.env.PUBLIC_URL+"./img/results/less_than_10.png",
	zero:process.env.PUBLIC_URL+"./img/results/zero.png",
	phonecall:process.env.PUBLIC_URL+"./img/results/phone.png",
	obj1:process.env.PUBLIC_URL+"./img/results/obj1.png",
	obj2:process.env.PUBLIC_URL+"./img/results/obj2.png",
	//icons//
	logouticon: process.env.PUBLIC_URL + "./img/icons/logout.svg",
	chaticon: process.env.PUBLIC_URL + "./img/icons/chat.svg",
	newchaticon: process.env.PUBLIC_URL + "./img/icons/newchat.svg",
	doublearrowupp: process.env.PUBLIC_URL + "./img/icons/angles-up-solid.svg",
	contactslash: process.env.PUBLIC_URL + "./img/icons/kontaktslash.png",
	contactusbutton:process.env.PUBLIC_URL + "./img/icons/contactusbutton.png",
	homebutton:process.env.PUBLIC_URL + "./img/icons/homebutton.png"
	//  vajrat images


};

export default imagePaths;
