import { React, useState, createContext, useContext, useEffect } from "react";
import axios from "axios";
import { baseURL, productsNameSearchURL } from "../services/ApiService";
import PropTypes from "prop-types";

const CartContext = createContext();

const useCartContext = () => {
	return useContext(CartContext);
};

const CartProvider = ({ children }) => {


	const [results, setResults] = useState([]);
	const [query, setQuery] = useState("");
	const [totalShuma, setTotalShuma] = useState(0);
	const kerkimi = query.toUpperCase();
	//--------------pagesa menyra
	const initialPagesa = localStorage.getItem("pagesaoption") || "Кеш";
	const [pagesaMenyra, setPagesaMenyra] = useState(initialPagesa);
	const initialDergesa = localStorage.getItem("dergimioption") || "Самоподигање";
	const [dergesaMenyra, setDergesaMenyra] = useState(initialDergesa);
	//----------------------------

	const initialCartItems =
		JSON.parse(localStorage.getItem("updatedcartstorage")) || [];
	const [cartItems, setCartItems] = useState(initialCartItems);

	//const checkUserId = localStorage.getItem("customerid");
	const initialRemovedCartItems =
		JSON.parse(localStorage.getItem("removeditemsUpdated")) || [];
	const [cartItemsRemoved /*, setCartItemsRemoved*/] = useState(
		initialRemovedCartItems
	);

	async function handleSearchQuery() {
		//console.log(kerkimi);
		try {
			const response = await axios.get(`${baseURL}${kerkimi}`);
			if (response.data.length === 0) {
				setResults([]);
			} else {

				setResults(response.data);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			console.log(results);
			setResults([]);
		}
	}
	async function handleSearchNameQuery() {
		//console.log(kerkimi);
		try {
			const response = await axios.get(`${productsNameSearchURL}${kerkimi}`);
			if (response.data.length === 0) {
				setResults([]);
			} else {
				const transformedResults = response.data.map((item) => ({
					id: item.id,
					oem_nr: item.upcsku,
					produkt_name: item.productname,
					vend_nr: item.vendoritemnumber,
					brand: item.brand,
					cmimi_pakice: item.salesprice,
					cmimi_shumice: item.salesstandardcost,
					pershkrimi: item.salesdescription,
					purchase_description: item.purchasedescription,
					kampanja: item.kampanja,
					cmim_kampanje: item.cmim_kampanje,
					data_kampanja: item.data_kampanja,
					sasia: item.sasia,
					sasia_1: item.sasia_1,
					sasia_2:item.sasia_2,
				}));
				setResults(response.data);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			console.log(results);
			setResults([]);
		}
	}
	
	/*
	useEffect(() => {
		if (results.length === 0) {
			setResults([]);
		}
	}, [results, setResults]);
	*/

	const handleAddToCartResult = (result) => {
		const token = localStorage.getItem("token");
		if (token) {
			const itemIndex = cartItems.findIndex(
				(cartItem) =>
					cartItem.oem_nr === result.oem_nr &&
					cartItem.vend_nr === result.vend_nr
			);

			if (itemIndex !== -1) {
				handleAddToCartItem(itemIndex);
			} else {
				setCartItems((prevCartItems) => [
					...prevCartItems,
					{ ...result, quantity: 1, shuma: result.cmimi_shumice },
				]);
			}
		} else {
			alert("Please log in first.");
		}
	};

	const handleAddToCartItem = (itemIndex) => {
		const updatedCart = [...cartItems];
		updatedCart[itemIndex].quantity += 1;
		updatedCart[itemIndex].shuma =
			updatedCart[itemIndex].cmimi_shumice * updatedCart[itemIndex].quantity;
		setCartItems(updatedCart);
	};

	const handleIncrementQuantity = (index) => {
		const updatedCart = [...cartItems];
		updatedCart[index].quantity += 1;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
		setCartItems(updatedCart);
	};

	const handleDecrementQuantity = (index) => {
		const updatedCart = [...cartItems];
		updatedCart[index].quantity = Math.max(0, updatedCart[index].quantity - 1);
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;

		if (updatedCart[index].quantity === 0) {
			updatedCart.splice(index, 1);
		}

		setCartItems(updatedCart);
	};
	const handleAddManualQuantity = (index, quantity) => {
		const updatedCart = [...cartItems];
		updatedCart[index].quantity = parseInt(quantity, 10) || 0;
		updatedCart[index].shuma =
			updatedCart[index].cmimi_shumice * updatedCart[index].quantity;
		/*
		if (updatedCart[index].quantity === 0) {
			updatedCart.splice(index, 1);
		}
*/
		setCartItems(updatedCart);
	};

	const handleRemoveItem = (index) => {
		const updatedCart = [...cartItems];
		updatedCart[index].quantity = 0;
		console.log('updated cart before:', updatedCart.length);
		
		if (updatedCart[index].quantity === 0) {
			updatedCart.splice(index, 1);
		}
	
		if (updatedCart.length === 0) {
			localStorage.removeItem("updatedcartstorage");
			setCartItems([]);
		} else {
			setCartItems(updatedCart);
		}
	
		//console.log('updated cart length fund ', updatedCart.length);
	};
	

	useEffect(() => {
		if (cartItems.length > 0) {
			const updatedItems = cartItems.map((item) => ({
				produkt_name: item.produkt_name,
				oem_nr: item.oem_nr,
				vend_nr: item.vend_nr,
				brand: item.brand,
				emri: item.produkt_name + "(" + item.brand + ")",
				id: item.id,
				cmimi_shumice: item.cmimi_shumice,
				quantity: item.quantity,
				//shuma:item.shuma,
				shuma: item.cmimi_shumice * item.quantity,
			}));

			const calculateTotalShuma = cartItems.reduce(
				(total, item) => total + item.shuma,
				0
			);
			setTotalShuma(calculateTotalShuma);
			localStorage.setItem("updatedcartstorage", JSON.stringify(updatedItems));
		}
	}, [cartItems, totalShuma]);
//console.log('query search',query)
	//console.log('initial cart items cartcontext??',initialCartItems)
	return (
		<CartContext.Provider
			value={{
				results,
				handleAddToCartItem,
				handleAddToCartResult,
				cartItems,
				setCartItems,
				query,
				setQuery,
				handleSearchQuery,
				handleIncrementQuantity,
				handleDecrementQuantity,
				totalShuma,
				cartItemsRemoved,
				pagesaMenyra,
				setPagesaMenyra,
				dergesaMenyra,
				setDergesaMenyra,
				handleAddManualQuantity,
				handleRemoveItem,
				handleSearchNameQuery,
				setResults,

			}}
		>
			{children}
		</CartContext.Provider>
	);
};
// Add prop validation for the 'children' prop
CartProvider.propTypes = {
	children: PropTypes.node,
};

export { CartProvider, CartContext, useCartContext };
