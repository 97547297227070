// Assuming you have REACT_APP_APP_NAME set in your environment variables
//import io from 'socket.io-client';

const baseserver = process.env.REACT_APP_APP_NAME === 'benfiko'

? 'https://interspace.freemyip.com:3010'
  : 'https://interspace.freemyip.com:3020'
//-------------------serveri puna
//? 'https://tetnet.freemyip.com:3010'
//  : 'https://tetnet.freemyip.com:3020'
//Lokalisht
//? 'https://127.0.0.1:3010'
//  : 'https://127.0.0.1:3020'

// eslint-disable-next-line
{/* ? 'https://185.226.0.16:3010'
: 'https://185.226.0.16:3020';*/}
console.log("baseserver: ",baseserver)

export const baseURL = `${baseserver}/products/mytecdoc/`;
export const loginURL = `${baseserver}/customers/login/`;
export const inventoryURL = `${baseserver}/inventory/`;
export const initialCartURL = `${baseserver}/tempitems/`;
export const historyOrderURL = `${baseserver}/orders/`;
export const itemsDetailURL = `${baseserver}/items/`;
export const chatUrl = `${baseserver}/chat`;
export const dealerordersURL= `${baseserver}/orders`;
export const productimageURL= `${baseserver}/productproperties/`;
export const customersURL= `${baseserver}/customers/`;
export const productsNameSearchURL= `${baseserver}/productsname/`;
export const firmaURL=`${baseserver}/firma`;
export const brandURL=`${baseserver}/productsbrand/`;
export const kampanjaURL=`${baseserver}/kampanja/`
//export const socket = io(`${baseserver}`); // Adjust the URL to match your server

// export const webpageurl = 'https://tetnet-pro.com/benfikorder';
